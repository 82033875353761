.agent-card .agent-card__content {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
}

.agent-card .agent-card__content .agent-card__name {
  display: flex;
  gap: var(--gap-md);
  justify-content: space-between;
  text-transform: capitalize;
}

.agent-card__content-info {
  display: flex;
  gap: var(--gap-md);
  justify-content: space-between;
  margin-right: 4.6rem;
}

.agent-card__agent-status {
  display: flex;
  gap: var(--gap-ss);
}

.agent-card__agent-status > img {
  width: 8rem;
  height: 8rem;
}

.agent-card .agent-card__status-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap-ss);
}
.agent-card .agent-card__status-list p {
  display: inline-flex;
  align-items: center;
  gap: .5rem;
  color: var(--agent-text-color);
}

.agent-card .agent-card__status-list p span {
  font-weight: 700;
}

.agent-card .agent-card__timing-analysis {
  display: flex;
  gap: var(--gap-md);
}