.overall-filters__def-range {
  display: flex;
  align-items: flex-end;
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--white);
  overflow: hidden;
  max-width: max-content;
}

.overall-filters__def-range p {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.024px;
  padding: var(--gap-ss);
  border-right: 1px solid var(--white);
  min-width: 9.6rem;
  cursor: pointer;
  transition: all 0.35s;
}

.overall-filters__def-range p:last-child {
  border-right-color: transparent;
}

.overall-filters__def-range p:hover {
  background-color: var(--accent-hover-color);
  color: var(--white);
}

.overall-filters__def-range p.active-tab {
  background-color: var(--accent-color);
  color: var(--white);
}
