.breadcrumbs ul {
  list-style: none;
  display: flex;
  padding: var(--gap-sm) 0 0;
}

.breadcrumbs ul li {
  display: inline;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.32px;
}

.breadcrumbs ul li a {
  text-decoration: none;
  color: var(--accent-color);
  transition: all 0.35s;
}

.breadcrumbs ul li a:hover {
  text-decoration: underline;
}

.breadcrumbs ul li::after {
  content: '>';
  padding: 0 10px;
}

.breadcrumbs ul li:last-child::after {
  content: '';
}
