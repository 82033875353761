.last-call {
  display: flex;
  gap: var(--gap-sm);
}

.last-call .last-call-info {
  flex: 1;
}

.last-call .last-call-info h3 {
  margin-bottom: var(--gap-ss);
}

.last-call .last-call-info p {
  margin-bottom: var(--gap-md);
}

.last-call .last-call-info p:last-of-type{
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .last-call {
    flex-direction: column;
  }
}