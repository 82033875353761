.categories-list .categories-item {
  position: relative;
}

.categories-list .categories-item .categories-item-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--card-border-color);
  background: var(--gradient-login);
  backdrop-filter: blur(8px);
  cursor: pointer;
}

.categories-list .categories-item .categories-item__content{
    background-color: var(--white);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--card-border-color);
    background: var(--white);
    position: absolute;
    bottom: 130%;
    left: -50%;
    padding: 1rem;;
    opacity: 0;
    transition: all .5s;
    min-width: 7rem;
    pointer-events: none;
}

.categories-list .categories-item .categories-item__content span {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: 700;
  color: var(--h-color);
}

.categories-list .categories-item .categories-item__content ul {
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: var(--text-color);
}

.categories-list .categories-item .categories-item__content ul li {
  position: relative;
  padding: 0 0 var(--gap-xs) var(--gap-xs);
}

.categories-list .categories-item .categories-item__content ul li:before {
  content: '';
  background-color: var(--accent-color);
  border-radius: 50%;
  height: .4rem;
  width: .4rem;
  position: absolute;
  top: .6rem;
  transform: translateY(-50%);
  left: 0;
}

.categories-list .categories-item .categories-item__content:after {
  content: '';
  border-width: 5px;
  border-style: solid;
  border-color: var(--card-border-color) transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 40%;
  margin-left: 5%;
}

.categories-list .categories-item .categories-item-img:hover + .categories-item__content {
  opacity: 1;
  transform: translateY(-10px);
  pointer-events: auto;
}

.categories-list .categories-item img {
  width: 3.2rem;
  height: 3.2rem;
}