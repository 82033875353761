.filter-panel {
  display: flex;
  flex-direction: column;
  gap: var(--gap-ss);
  flex-grow: 1;
  position: absolute;
  left: var(--gap-md);
  right: var(--gap-md);
  top: var(--gap-md);
  padding-right: var(--gap-ss);
  height: calc(100vh - 8.1rem);
  min-height: 100%;
  overflow-y: auto;
}

.filter-panel label:not(.dropdown-list-item label),
.overall-stats-filters label {
  color: var(--label-text);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: .4rem;
}

.filter-panel input:not(.picker-container input, .dropdown-body input),
.overall-stats-filters input,
.filter-panel select:not(.picker-container select) {
  width: 100%;
  padding: var(--gap-xs) var(--gap-sm);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--card-border-color);
  background: var(--white);
}

.filter-panel .number-of-calls .call-number-inputs {
  display: flex;
}
.filter-panel .number-of-calls .call-number-inputs input[type="number"]:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  text-align: center;
}

.filter-panel .number-of-calls .call-number-inputs input[type="number"]:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  text-align: center;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Slider Range */
.horizontal-slider {
  width: 100%;
  height: 0.8rem;
  margin: var(--gap-sm) 0;
  border-radius: 123.4rem;
  background: var(--accent-overlay, #F9ECE6);
  border: 1px solid var(--accent-overlay);
}

.thumb {
  top: -10px;
  height: 2.4rem;
  width: 2.4rem;
  background-color: var(--accent-color);
  border-radius: 123.4rem;
  outline: 4px solid #FFF;
  background: var(--accent-color);
  box-shadow: 0px 6px 12px 0px rgba(16, 24, 40, 0.10);
  cursor: grab;
}

.thumb-content {
  position: absolute;
  top: 100%;
  padding: 2px 5px;
  color: var(--h-color);
  font-size: 1.6rem;
  font-style: normal;
  line-height: 2rem; 
}

.thumb-0 .thumb-content {
  left: -1rem;
}

.thumb-1 .thumb-content {
  left: 1rem;
}

.track {
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

.track-1 {
  background: var(--accent-color);
}

.call-range-values {
  display: flex;
  justify-content: space-between;
}

.call-range-values span {
  color: var(--h-color);
  font-weight: 600;
}
/* Slider Range */