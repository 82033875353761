:root {
  --sidebar-color: #ffffff;
  --primary-color: #F68458;
  --primary-color-hover: #F9A888;
  --primary-color-menu-hover: #F9ECE6;
  --menu-text-color: #26383B;
  --body-color: #f5f5f5;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.4s ease;
  --tran-05: all 0.5s ease;
}

.css-dip3t8 {
  overflow-y: initial!important;
  overflow-x: visible!important;
}

.sidebar-container {
  display: flex;
  height: 100vh;
  position: relative;
}

.sidebar-container__right {
  padding: var(--gap-md);
}

.ps-sidebar-root.css-15eyxto {
  min-width: 31rem;
}

.sidebar-container aside {
  position: fixed;
  top: 8rem;
  height: 100svh;
}

.ps-sidebar-container {
 padding: var(--gap-ss);
}

.ps-menu-root {
  padding-top: var(--gap-lg);
}

.sidebar-header {
  position: relative;
  padding: 10px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-collapser {
  position: absolute;
  right: -12px;
  background-color: var(--primary-color);
  color: var(--sidebar-color);
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--tran-05);
}

.sidebar-collapser:hover {
  background-color: var(--primary-color-hover);
}

/* Sidebar menu */
.ps-menu-button {
  transition: var(--tran-03);
  border-radius: 6px;
}

.ps-sidebar-root.ps-collapsed .ps-menu-button {
  padding: 0 10px;
}

.ps-menu-button.ps-active {
  background-color: var(--primary-color-menu-hover)!important;
}

.ps-menu-button:hover {
  background-color: var(--primary-color)!important;
}

.ps-menuitem:hover {
  background-color: var(--primary-color);
}

.ps-menu-label {
  transition: var(--tran-03);
  font-weight: 500;
}

.ps-collapsed .ps-menu-button::after {
  content: attr(data-menu-item);
  position: absolute;
  left: 6.6rem;
  bottom: 100%;
  transform: translateX(-1rem);
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--white);
  padding: 2px 6px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  z-index: 10;
}

.ps-collapsed .ps-menu-button:hover::after {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.ps-menu-button:hover .ps-menu-label {
  color: var(--sidebar-color);
}

.menu-icon.menu-icon--fill path{
  fill: var(--text-color);
  transition: var(--tran-03);
}

.menu-icon.menu-icon--stroke path{
  stroke: var(--text-color);
  transition: var(--tran-03);
}

.ps-menu-button:hover .menu-icon.menu-icon--fill path {
  fill: var(--white)
}

.ps-menu-button:hover .menu-icon.menu-icon--stroke path {
  stroke: var(--white)
}

.ps-submenu > .ps-submenu-content {
  padding-left: 20px;
}

/* Dark mode switch */
.dark-mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
}

.dark-mode .sun-moon i {
  font-size: 18px;
}
