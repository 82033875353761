.time-selector {
  display: flex;
  gap: var(--gap-sm);
  justify-content: center;
  padding: var(--gap-sm);
  border-left: 1px solid var(--card-border-color);
}

.date-range-selector.date-range-selector--time .rdrDefinedRangesWrapper {
  display: none;
}

.date-range-selector.date-range-selector--time .button-container {
  margin-right: 0;
}

.time-selector .time-selector-box {
  display: flex;
  flex-direction: column;
}

.react-time-picker__wrapper {
  border-color: transparent!important;
}

.react-time-picker__inputGroup__input {
  height: auto!important;
}

.react-time-picker__inputGroup__input .react-time-picker__inputGroup__amPm {
  border-color: transparent!important;
}
