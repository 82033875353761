input.apple-switch {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 40px; 
  height: 24px;
  background-color: var(--white);
  border: 1px solid #D9DADC;
  border-radius: 40px;
  box-shadow: inset -16px 0 0 0 var(--white);
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}

input.apple-switch:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  transition: left 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

input.apple-switch:checked {
  box-shadow: inset 16px 0 0 0 var(--success);
  border-color: var(--success);
}

input.apple-switch:checked:after {
  left: 16px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.2);
}
