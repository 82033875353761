*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


:root {
  font-size: 10px;
  /* Colors */
  --accent-color: #F68458;
  --accent-hover-color: #F9A888;
  --accent-active-color: #EF7648;
  --accent-color-input: #DDDDDD;
  --accent-overlay: #F9ECE6;
  --card-bg-color: #F6EEEA;
  --card-border-color: rgba(118, 143, 147, 0.60);
  --dropdown-item-hover: rgba(246, 132, 88, 0.60);

  --circle-grey-color: #A9B5B6;

  --header-bg-color: rgba(255, 255, 255, 0.56);
  --detail-item-bg-color: rgba(255, 255, 255, 0.6);
  --call-item-bg-color: rgba(246, 132, 88, 0.25);
  --text-color: #26383B;
  --agent-text-color: #587074;
  --agent-stats-text-color: #505050;
  --label-text: #587074;
  --h-color: #26383B;
  --search-bar-color: #F7F6F6;
  --state-text-color: #85A8AD;
  --white: #FFFFFF;
  --black: #000000;
  --error: #DA3F3F;
  --error-bg: rgba(218, 63, 63, 0.3);
  --success: #5ce04d;
  --success-bg: rgba(92, 224, 77, 0.3);

  --inbound-bg: rgba(92, 224, 77, 0.6);
  --outbound-bg: rgba(242, 239, 59, 0.6);
  
  /* Fonts */
  --userfont: 'Poppins', sans-serif;

  /* Gradients */
  --gradient-login: linear-gradient(130deg, rgba(255, 255, 255, 0.00) 0%, rgba(189, 222, 226, 0.40) 25.11%, rgba(189, 222, 226, 0.30) 75.13%, rgba(255, 255, 255, 0.00) 100%), rgba(255, 255, 255, 0.10);
  --gradient-error: linear-gradient(130deg, rgba(185, 58, 58, 0) 0%, rgba(243, 24, 24, 0.4) 25.11%, rgba(191, 40, 40, 0.3) 75.13%, rgba(168, 43, 43, 0) 100%), rgba(134, 38, 38, 0.1);
  /* Shadows */
  --secondary-hover-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  --secondary-active-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Sizes */
  --border-radius-ss: .4rem;
  --border-radius-sm: .8rem;
  --border-radius-md: 1.2rem;
  --border-radius-lg: 1.6rem;
  --border-radius-xl: 2.4rem;

  --section-margin: 4rem;

  --gap-xxl: 4.8rem;
  --gap-xl: 4rem;
	--gap-lg: 3.2rem;
  --gap-md: 2.4rem;
  --gap-sm: 1.6rem;
  --gap-ss: 1.2rem;
  --gap-xs: .8rem;

  /* Grids */
  --column: 80px;
  --column-gap: 24px;

  /* Elements */
  --header-height: 4.4rem;
}

/* poppins-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/poppins-v21-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/poppins-v21-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/poppins-v21-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/poppins-v21-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
iframe {
  border: none;
}

ul, ol {
  list-style: none;
}

h1, h2 {
  color: var(--h-color);
}

h1 {
  font-size: 4.8rem;
  font-weight: 600;
  line-height: 5.6rem;
  margin-bottom: 2.4rem;
}

h2 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 700;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

input, textarea {
  font-family: var(--userfont);
  font-size: 1.6rem;
  line-height: 2.4rem;
}

textarea {
  resize: none;
}

::placeholder {
  color: var(--label-text);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  opacity: 0.6;
}

body {
  overflow-x: hidden;
  font-family: var(--userfont);
  color: var(--text-color);
  background: url(./assets/images/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
}

p {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

button {
  background: none;
  border: none;
}

.container {
  max-width: calc(var(--column) * 12 + var(--column-gap) * 11);
  margin: 8rem auto 0;
}

.container-chat {
  margin: 8rem auto 0;
}

.container-center {
  max-width: calc(var(--column) * 9 + var(--column-gap) * 8);
  margin: 0 auto;
}

.card-wrapper {
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--card-border-color);
  background: var(--white);
  padding: var(--gap-md);
}

.card-wrapper.card-wrapper--sm {
  padding: var(--gap-sm);
}

.card-wrapper.card-wrapper--ss {
  padding: var(--gap-ss);
}

.card-wrapper--agent {
  background: var(--detail-item-bg-color);
  border: none;
}

.circle {
  position: relative;
  width: 6.4rem;
  height: 6.4rem;
  background: conic-gradient(from 0deg, var(--clr) 0%, var(--i), #A9B5B6 var(--i), #A9B5B6 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle:before {
  content: '';
  position: absolute;
  inset: 5px;
  background-color: var(--white);
  border-radius: 50%;
}

.circle h4 {
  position: relative;
  text-align: center;
  color: var(--clr);
}

.featured-text {
  color: var(--accent-color);
  font-weight: 700;
}

.coming-soon-featured-text {
  color: green;
  font-weight: 300;
}

p > ul {
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--gap-xl);
  row-gap: var(--gap-sm);
  list-style: none;
  margin: var(--gap-md);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

p > ul li {
  flex: 1 0 calc((100% - var(--gap-xl)) / 2);
  max-width: calc((100% - var(--gap-xl)) / 2);
  position: relative;
}

p > ul li:last-of-type {
  margin-bottom: 0;
}

p > ul li::before {
  position: absolute;
  top: 0;
  left: -1.6rem;
  display: block;
  content: '';
  min-width: .8rem;
  height: .8rem;
  border-radius: 100%;
  background: var(--accent-color);
  margin-top: 0.8rem;
}

.vert-divider {
  display: inline-block;
  width: 0.2rem;
  height: 2.4rem;
  border-radius: 0.2rem;
  opacity: 0.6;
  background: var(--accent-color);
  margin: 0 var(--gap-sm);
}

.section-search-filter {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
  padding: var(--section-margin) 0;
}

.search-bar-filter {
  display: flex;
  gap: var(--column-gap);
  align-items: flex-start;
}

.search-bar {
  margin-bottom: var(--gap-lg);
  min-width: 100%;
}

.overall-filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: var(--gap-md);
}

.overall-filters__range {
  min-width: 28rem;
}

.customer-summary,
.agent-summary {
  padding: var(--section-margin) 0;
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
}

.cta {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap-ss);
  border-radius: var(--border-radius-sm);
  padding: var(--gap-ss) var(--gap-md);
  cursor: pointer;
  transition: all .35s;
  min-width: 18rem;
}

.cta.cta-main {
  color: var(--white);
  background: var(--accent-color);
}

.cta.cta-main:hover {
  background-color: var(--accent-hover-color);
}

.cta.cta-main:active {
  background-color: var(--accent-active-color);
}

.cta.cta-main:disabled,
.cta.cta-secondary:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.cta.cta-secondary {
  color: var(--text-color);
  border: 1px solid var(--card-border-color);
}

.cta.cta-secondary:hover {
  border-color: var(--accent-color);
  box-shadow: var(--secondary-hover-shadow);
}

.cta.cta-secondary:active {
  box-shadow: var(--secondary-active-shadow);
}

.cta.cta-secondary.cta--pagination {
  min-width: 4.8rem;
  padding: var(--gap-ss);
  background-color: var(--white);
}

.cta.cta-secondary.cta--pagination:active {
  background-color: var(--accent-color);
  color: var(--white)
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: var(--gap-ss);
  padding: var(--gap-sm);
  margin-right: 19.9rem;
  border-right: 1px solid var(--card-border-color);
}

.container-login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--section-margin) 0;
  max-width: 62rem;
  height: 100vh;
}

.google-login-container {
  display: flex;
  padding: 12.8rem 6.4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--gap-lg);
  border-radius: var(--border-radius-xl);
  border: 1px solid var(--border, rgba(118, 143, 147, 0.60));
  background: var(--gradient-login);
  backdrop-filter: blur(8px);
}

.google-btn {
  background-color: var(--white);
  gap: var(--gap-sm);
}

.categories-list {
  display: flex;
  justify-content: flex-end;
  gap: var(--gap-sm);
}

/* Tabs */
.rc-tabs {
  border-color: transparent;
  overflow: visible;
}

.rc-tabs-tab {
  background: none;
}

.rc-tabs-nav-wrap {
  background-color: var(--white);
  border-radius: var(--border-radius-lg);
  border-color: transparent;
  padding: var(--gap-md) var(--gap-sm);
  min-width: 100%;
}

.rc-tabs-nav-list {
  gap: var(--gap-md);
  justify-content: space-between;
}

.rc-tabs {
  border: none !important;
}

.rc-tabs-ink-bar {
  background-color: var(--accent-color)!important;
}

.tab-content {
  padding: var(--gap-md) 0;
}
/* Tabs */


/* Table */
.table-container {
  padding: 20px;
  background-color: #f9fafb; /* Light grey background */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

/* Table styling */
.styled-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
}

/* Header styling */
.styled-table thead {
  background-color: #f7f8fa;
  border-bottom: 2px solid #ddd;
}

.styled-table th {
  padding: 16px;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  color: #333;
  border-right: 1px solid #ddd;
}

.styled-table th:last-child {
  border-right: none;
}

/* Body styling */
.styled-table tbody tr {
  background-color: white;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f7f8fa; /* Alternate row color */
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1; /* Hover effect */
}

.styled-table td {
  padding: 16px;
  color: #333;
  font-size: 14px;
  text-align: left;
  border-right: 1px solid #ddd;
}

.styled-table td:last-child {
  border-right: none;
}

/* Rounded corners for the first and last rows */
.styled-table tbody tr:first-child td:first-child {
  border-top-left-radius: 12px;
}

.styled-table tbody tr:first-child td:last-child {
  border-top-right-radius: 12px;
}

.styled-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 12px;
}

.styled-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}

.styled-table tbody td img {
  width: 12px;
  height: 12px;
}
/* Table */

df-messenger {
  z-index: 999;
  position: fixed;
  --df-messenger-input-box-border-radius: 16px;
  --df-messenger-input-box-border: 1px solid rgba(118, 143, 147, 0.60);
  --df-messenger-font-color: var(--text-color);
  --df-messenger-font-family: var(--userfont);
  --df-messenger-chat-background: #f3f6fc;
  --df-messenger-message-user-background: #d3e3fd;
  --df-messenger-message-bot-background: #fff;
  bottom: 0;
  right: 0;
  top: 0;
  width: 350px;
  display: none;
}

.input-element-wrapper {
  border-radius: var(--border-radius-md);
}

@media (max-width: 1240px) {
  .container,
  .container-center {
    flex-direction: column;
    margin: 0 var(--gap-sm);
  }

  .sidebar-container {
    gap: var(--gap-sm);
  }

  .search-bar {
    min-width: 100%;
  }
}

@media (max-width: 992px) {
  :root {
    font-size: 10px;
  }

  h1 {
    font-size: 4rem;
    line-height: 4.8rem;
  }
  
  h2 {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
}

@media (max-width: 576px) {
  p > ul li {
    flex: 1 1 100%;
    max-width: 100%;
  }
}