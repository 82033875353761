/* src/components/CopyButton.css */
.copy-button-container {
    display: inline-flex;
    align-items: center;
    position: relative;
  }
  
  .copy-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .copy-button img {
    width: 16px;  /* Adjust the width as needed */
    height: 16px; /* Adjust the height as needed */
    object-fit: contain;  /* Ensure the icon fits within the specified size */
  }
  
  .copy-acknowledgment {
    margin-left: 8px;
    color: green;
    font-size: 0.9em;
    position: absolute;
    left: 20px;
    top: -20px;
  }
  