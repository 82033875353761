.search-input {
  position: relative;
  max-width: 50%;
}

.search-input__input {
  position: relative;
  z-index: 1;
}

.search-input input[type="search"] {
  border-radius: var(--border-radius-sm);
  border: 2px solid var(--accent-color-input);
  background: var(--white);
  padding: var(--gap-ss) var(--gap-xxl);
  width: 100%;
}

.search-input input[type="search"]:focus {
  border: 2px solid var(--accent-color-input);
  outline: none;
}

.search-input .search-icon {
  position: absolute;
  left: var(--gap-sm);
  top: 50%;
  transform: translateY(-50%);
}

.search-input__params {
  position: relative;
  top: -10px;
  background: var(--white);
  padding: 1.5rem var(--gap-sm) .5rem;
  display: flex;
  gap: var(--gap-ss);
  border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
  border: 1px solid transparent;
}

.search-input__params label {
  display: flex;
  gap: .5rem;
  align-items: center;
  padding: .6rem;
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--accent-color);
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
  letter-spacing: 0.048px;
  color: var(--black);
  cursor: pointer;
  transition: all .35s;
}

.search-input__params label:hover {
  background: var(--accent-hover-color);
}

.search-input__params input[type="radio"]:checked + label {
  background: var(--accent-color);
}

.filter-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--gap-sm);
  cursor: pointer;
  height: var(--gap-xl);
  width: var(--gap-xl);
}