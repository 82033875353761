.chatbot-container {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  height: 100vh;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  box-shadow: var(--secondary-hover-shadow);
  z-index: 1000;
}

.chatbot-header {
  padding: var(--gap-sm);
  background-color: var(--accent-color);
  color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
}

.chatbot-header-title {
  display: flex;
  align-items: center;
  gap: var(--gap-ss);
}

.chatbot-header-title h2 {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
}

.chatbot-close-button {
  background: none;
  border: none;
  color: var(--white);
  cursor: pointer;
  padding: var(--gap-ss);
  border-radius: var(--border-radius-ss);
  transition: background-color 0.2s;
}

.chatbot-close-button:hover {
  background-color: var(--accent-hover-color);
}

.chatbot-messages {
  flex: 1;
  overflow-y: auto;
  padding: var(--gap-sm);
  display: flex;
  flex-direction: column;
  gap: var(--gap-ss);
}

.message-container {
  display: flex;
  gap: var(--gap-ss);
  max-width: 80%;
}

.user-message {
  flex-direction: row-reverse;
  margin-left: auto;
}

.message-avatar {
  min-width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-message .message-avatar {
  background-color: var(--accent-color);
  color: var(--white);
}

.bot-message .message-avatar {
  background-color: var(--card-border-color);
  color: var(--agent-text-color);
}

.message-content {
  padding: var(--gap-ss);
  border-radius: var(--border-radius-sm);
  font-size: 1.4rem;
  line-height: 2.0rem;
}

.user-message .message-content {
  background-color: var(--accent-color);
  color: var(--white);
}

.bot-message .message-content {
  background-color: var(--detail-item-bg-color);
  color: var(--text-color);
}

.chatbot-input-form {
  padding: var(--gap-sm);
  border-top: 1px solid var(--card-border-color);
  display: flex;
  gap: var(--gap-ss);
}

.chatbot-input {
  flex: 1;
  padding: var(--gap-ss);
  border: 1px solid var(--accent-color-input);
  border-radius: var(--border-radius-sm);
  font-size: 1.4rem;
}

.chatbot-input:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(246, 132, 88, 0.2);
}

.chatbot-send-button {
  background-color: var(--accent-color);
  color: var(--white);
  border: none;
  padding: var(--gap-ss);
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: background-color 0.2s;
}

.chatbot-send-button:hover {
  background-color: var(--accent-hover-color);
}
