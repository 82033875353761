.date-range-selector {
  position: relative;
}

.filter-panel .date-range-selector input[type="text"],
.overall-stats-filters .date-range-selector input[type="text"] {
  padding-left: 4.4rem;
}

.date-range-selector img {
  position: absolute;
  top: 50%;
  left: 1.6rem;
  transform: translateY(-50%);
}

.date-range-selector .picker-container {
  position: fixed;
}