.iframe-container {
  flex-grow: 1;
  background-color: #f5f5f5;
  margin: 8rem 0 0 31rem;
}

.sidebar-container:has(.ps-sidebar-root.ps-collapsed) .iframe-container {
  margin-left: 7.9rem;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}
