.dropdown {
  position: relative;
  width: 100%;
}

.dropdown-header {
  border: 1px solid var(--card-border-color);
  padding: var(--gap-xs) var(--gap-sm);
  border-radius: var(--border-radius-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 9.4rem;
  overflow: hidden;
}

.dropdown-header .arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2rem;
  height: 2rem;
}

.clear-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: var(--gap-ss);
}

.dropdown-body {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(-10px);
  width: 100%;
  background: var(--white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-md);
  padding: var(--gap-ss);
  opacity: 0;
  z-index: -1;
  transition: all .35s;
}

.dropdown-body.open {
  transform: translateY(0);
  opacity: 1;
  z-index: 1;
}

.dropdown-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
  max-height: 20rem;
  overflow-y: auto;
}

.dropdown-list-item {
  border-radius: var(--border-radius-sm);
  cursor: pointer;
}

.dropdown-list-item > * {
  padding: var(--gap-xs);
}