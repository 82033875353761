.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  border: 1px solid var(--border, rgba(118, 143, 147, 0.60));
  background: rgba(218, 63, 63, .6);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  color: var(--white);
  padding: var(--gap-sm);
  z-index: 1000;
}

.popup-content {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}