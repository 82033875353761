.call-card {
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);
}

.call-card h4 {
  display: flex;
  gap: 0.4rem;
  align-items: center;
}

.call-card h4 span {
  min-width: .8rem;
  height: .8rem;
  border-radius: 100%;
  background: var(--rlv);
}

.call-card .call-card__caller {
  display: flex;
  justify-content: flex-end;
}

  .call-card .call-card__caller span {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    padding: var(--gap-xs);
    color: var(--white);
    background: var(--accent-color);
    text-align: center;
    border-radius: var(--border-radius-sm);
}

.call-card .call-card__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.call-card .call-card__metrics {
  display: flex;
  gap: var(--gap-sm);
  flex-wrap: wrap;
  padding: var(--gap-ss) var(--gap-md);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--card-border-color);
  background: var(--gradient-login);
  backdrop-filter: blur(8px);
}

.call-card__info .call-card__info-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0.8rem;
  background: var(--inbound-bg);
  position: relative;
}

.call-card__info-icon span {
  position: relative;
}

.call-card__info .call-card__info-icon img {
  height: 1.2rem;
  width: 1.2rem;
}

.call-card__metrics-card {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap-xs);
  position: relative;
}

.call-card__metrics-card span {
  position: relative;
}

.call-card__metrics-card img {
  height: 2.4rem;
  width: 2.4rem;
}
.call-card__metrics-card p {
  font-weight: 500;
}

.call-card .call-card__line {
  height: 1px;
  width: 100%;
  background: var(--card-border-color);
}

.call-card .call-card__info p {
  font-weight: 500;
}

.call-card__audio audio {
  min-width: 100%;
  width: 25rem;
}

.call-card__metrics-card span::after,
.call-card__info-icon::after {
  content: attr(data-alt);
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--white);
  padding: 2px 6px;
  border-radius: 8px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  z-index: 10;
}

.call-card__metrics-card span:hover::after,
.call-card__info-icon:hover::after {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 768px) {
  .call-card .call-card__info {
    flex-direction: column;
    gap: .8rem;
    justify-content: center;
    align-items: center;
  }
}