.fixed-icon {
  z-index: 3;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.35s;
  height: 4.8rem;
  width: 4.8rem;
}

.fixed-icon.login-feedback-icon {
  position: fixed;
  top: 5rem;
  right: 5rem;
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  background: var(--accent-color);
  opacity: 0.7;
  cursor: pointer;
}

.fixed-icon.login-feedback-icon:hover {
  opacity: 1;
}

.fixed-icon.header-feedback-icon {
  gap: var(--gap-xs)
}

.feedback-form-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  transition: all .35s;
}

.feedback-form-container {
  position: absolute;
  right: var(--gap-sm);
  top: var(--gap-sm);
  bottom: var(--gap-sm);
  background-color: var(--white);
  width: 40rem;
  padding: var(--gap-sm);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  max-height: calc(100vh - var(--gap-sm));
  transform: translateX(200%);
  transition: transform 0.35s ease-in-out;
}

.feedback-form-container.open {
  transform: translateX(0);
}

.close-button {
  position: absolute;
  top: var(--gap-sm);
  right: var(--gap-sm);
  cursor: pointer;
}

.feedback-form-container h2 {
  margin-bottom: var(--gap-sm);
}

.feedback-form-container form textarea {
  width: 100%;
  height: 16rem;
  margin-bottom: var(--gap-md);
}

.feedback-form-container .feedback-form-input {
  border: 1px solid var(--card-border-color);
  padding: var(--gap-xs) var(--gap-sm);
  border-radius: var(--border-radius-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 9.4rem;
  width: 100%;
  overflow: hidden;
  margin-bottom: var(--gap-md);
}

.feedback-form-container label {
  color: var(--label-text);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: .4rem;
  position: relative;
}

.feedback-form-container label span {
  display: inline;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--accent-color);
  opacity: 0.7;
}

.feedback-form-input.form-input-error,
textarea.form-input-error {
  border-color: var(--error);
}

.error-text {
  position: absolute;
  width: max-content;
  left: 0;
  color: var(--error);
  font-size: 1rem;
}

.feedback-form-input + .error-text {
  bottom: 24px;
}

textarea + .error-text {
  bottom: 6px;
}

.feedback-submission-success {
  border-radius: var(--border-radius-md);
  border: 1px solid var(--success);
  background-color: var(--success-bg);
  margin-top: 1rem;
  padding: var(--gap-ss);
}

.feedback-submission-error {
  border-radius: var(--border-radius-md);
  border: 1px solid var(--error);
  background-color: var(--error-bg);
  margin-top: 1rem;
  padding: var(--gap-ss);
}

.feedback-loading-status {
  border-radius: var(--border-radius-md);
  border: 1px solid var(--accent-color);
  background-color: var(--accent-overlay);
  margin-top: 1rem;
  padding: var(--gap-ss);
  margin-bottom: var(--gap-sm);
}

.screenshot-preview {
  position: relative;
  margin-bottom: var(--gap-md);
}

.screenshot-preview .delete-screenshot {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.feedback-form-overlay .cta.cta-main {
  margin: 0 auto;
}

.feedback-form-overlay .cta.cta-secondary {
  min-width: 100%;
  margin-bottom: var(--gap-md);
}