.single-select-dropdown .dropdown-search-box {
  position: relative;
  margin-bottom: var(--gap-ss);
}

.single-select-dropdown .dropdown-search {
  width: 100%;
  padding: var(--gap-xs) var(--gap-ss) var(--gap-xs) var(--gap-xl);
  border: none;
  border-radius: var(--border-radius-sm);
  background-color: var(--search-bar-color);
}

.single-select-dropdown .dropdown-search:focus {
  border-color: var(--accent-color);
}

.single-select-dropdown .dropdown-search-icon {
  position: absolute;
  left: var(--gap-ss);
  top: 50%;
  transform: translateY(-50%);
}

.single-select-dropdown .dropdown-list {
  max-height: 220px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
}

.single-select-dropdown .dropdown-list-item {
  padding: var(--gap-xs);
  cursor: pointer;
  transition: all .35s;
}

.single-select-dropdown .dropdown-list-item:hover {
  background-color: var(--accent-hover-color);
}

.single-select-dropdown .selected-label {
  background-color: transparent;
}

.selected-label .remove-icon {
  height: 1.2rem;
  width: 1.2rem;
  cursor: pointer;
}
