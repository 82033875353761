.filter-sidebar-wrapper {
  position: fixed;
  top: 8.1rem;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  transition: transform 0.35s ease-in-out;
  transform: translateX(-100%);
  z-index: 2;
  pointer-events: none;
}

.filter-sidebar-wrapper.open {
  transform: translateX(0);
  pointer-events: auto;
}

.filter-sidebar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.filter-sidebar-content {
  background-color: #fff;
  width: 400px;
  max-width: 100%;
  height: calc(100vh - 8.1rem);
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  position: relative;
}
