/* src/components/CustomerItem/CustomerItem.css */
.patient-card-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);
}

.customer-header {
  display: flex;
  align-items: center;
  
}

.customer-name-link {

  text-decoration: none;
  color: inherit;
  margin-right: 15px; 
}

.open-in-new-tab-link {
  display: inline-block;
}

.open-in-new-tab-link img {
  width: 16px;  /* Adjust the width as needed */
  height: 16px; /* Adjust the height as needed */
  object-fit: contain;  /* Ensure the icon fits within the specified size */
}

.patient-card {
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);
  text-decoration: none;
  color: inherit;
}

.patient-card__content-link {
  text-decoration: none;
  color: inherit;
}

.patient-card__content {
  display: flex;
  justify-content: space-between;
  gap: var(--gap-sm);
}

.patient-card__content-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap-sm);
}

.patient-card__content-info img {
  width: 8rem;
  height: 8rem;
  object-fit: cover;
}

.patient-card__content-text {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.patient-card__content-location {
  display: flex;
  /* flex-direction: column; */
  flex-direction: row-reverse;
  align-items: center;
  gap: 0.4rem;
}

.patient-card__content-location img{
  width: 4.8rem;
  height: 4.8rem;
  object-fit: cover;
}

.patient-card__content-location p{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.6rem;
}

.linked-numbers-container {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
}

.linked-number {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;
}

.linked-number::after {
  content: attr(data-relation);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);;
  color: var(--white);
  padding: 2px 6px;
  border-radius: 8px;
  white-space: nowrap;
  font-size: 12px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
}

.linked-number:hover::after {
  visibility: visible;
  opacity: 1;
}

.show-more {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.linked-numbers-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  vertical-align: bottom;
}

.customer-btn-edit {
  cursor: pointer;
}

@media (max-width: 768px) {
  .patient-card__content-location {
    justify-content: center;
    flex-direction: column;
  }

  .patient-card__content,
  .patient-card__content-info {
    flex-direction: column;
  }

  .patient-card__content-location p {
    align-items: center;
  }

}
