.city-dropdown .dropdown-header {
  border: 1px solid var(--card-border-color);
}

.city-dropdown .dropdown-search-box {
  position: relative;
  margin-bottom: var(--gap-ss);
}

.city-dropdown .dropdown-search {
  width: 100%;
  padding: var(--gap-xs) var(--gap-ss) var(--gap-xs) var(--gap-xl);
  border: none;
  border-radius: var(--border-radius-sm);
  background-color: var(--search-bar-color);
}

.city-dropdown .dropdown-search:focus {
  border-color: var(--accent-color);
}

.city-dropdown .dropdown-search-icon {
  position: absolute;
  left: var(--gap-ss);
  top: 50%;
  transform: translateY(-50%);
}

.city-dropdown .dropdown-list-item {
  display: flex;
  align-items: center;
  transition: all .35s;
}

.city-dropdown .dropdown-list-item label {
  position: relative;
  display: flex;
  gap: var(--gap-xs);
  align-items: flex-start;
  flex-grow: 1;
  margin-bottom: 0;
  border: 1px solid transparent;
  border-radius: var(--border-radius-sm);
}

.city-dropdown .dropdown-list-item label input[type="checkbox"] {
  display: none;
  transition: all .35s;
}

.dropdown-list-item label:hover {
  border: 1px solid var(--dropdown-item-hover);
}

.city-dropdown .dropdown-list-item label:has(input[type="checkbox"]:checked) {
  background-color: var(--accent-overlay);
}

.city-dropdown .dropdown-list-item label:has(input[type="checkbox"])::before {
  content: '';
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem;
  border: 1.5px solid var(--card-border-color);
  background: var(--white);
  min-width: 2.4rem;
  min-height: 2.4rem;
}

.city-dropdown .dropdown-list-item  label:has(input[type="checkbox"]:checked):before {
  border-color: var(--accent-color);
  background-color: var(--accent-color);
}

.city-dropdown .dropdown-list-item   label:has(input[type="checkbox"]:checked):after {
  content: '';
  position: absolute;
  left: 0.8rem;
  top: 0.8rem;
  background: url('../../assets/images/svg/btn-checkbox.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 2.4rem;
  height: 2.4rem;
}

.city-dropdown .dropdown-list-item label > input[type="checkbox"] + span {
  display: flex;
  flex-grow: 1;
  align-self: center;
  color: var(--text-color);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
}

.city-dropdown .dropdown-list-item label > input[type="checkbox"] + span + span {
  color: var(--state-text-color);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}

.selected-labels {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.4rem;
  row-gap: 0.4rem;
  max-height: 8rem;
  overflow-y: auto;
}

.selected-label {
  display: inline-flex;
  gap: 0.4rem;
  align-items: center;
  background-color: var(--accent-overlay);
  padding: .4rem;
  cursor: pointer;
  user-select: none;
}

.selected-label .remove-icon {
  height: 1.2rem;
  width: 1.2rem;
  cursor: pointer;
}