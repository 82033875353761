.calls-table {
  width: 100%;
  border-collapse: collapse;
}

.calls-table-header {
  font-weight: bold;
  text-align: left;
  
}

.calls-table-header th:first-child {
  border-top-left-radius: var(--border-radius-lg);
  border-bottom-left-radius: var(--border-radius-lg);
}

.calls-table-header th:last-child {
  border-top-right-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
}

.calls-table-header th {
  background-color: var(--header-bg-color);
}

.calls-table-row td:first-child {
  border-top-left-radius: var(--border-radius-lg);
  border-bottom-left-radius: var(--border-radius-lg);
}

.calls-table-row td:last-child {
  border-top-right-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
}

.calls-table th,
.calls-table td {
  padding: var(--gap-sm);
}

.calls-table-row__info {
  display: flex;
  align-items: center;
}

.calls-table-row__info-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0.8rem;
  height: 2.8rem;
  width: 2.8rem;
  background: var(--inbound-bg);
  position: relative;
}
.calls-table-row {
  border-radius: 16px;
  background-color: var(--call-item-bg-color);
}

.calls-table-row__info-icon img {
  height: 1.2rem;
  width: 1.2rem;
}

.calls-table-body tr {
  cursor: pointer;
}

.call-details {
  /* padding: var(--gap-sm); */
  background-color: var(--call-details-bg-color);
}

.sort-container {
  display: flex;
  align-items: center;
  gap: .4rem;
  cursor: pointer;
}

th img.sort-icon {
  margin-left: 4px;
  width: 24px;
  height: 24px;
}

.slide-enter {
  opacity: 0;
  transform: translateY(-12px);
}

.slide-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.5s ease-in;
}

.slide-exit {
  opacity: 1;
  transform: translateY(0);
}

.slide-exit-active {
  opacity: 0;
  transform: translateY(-12px);
  transition: all 0.5s ease-out;
}

