.header {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: var(--white);
  padding: var(--gap-sm) var(--gap-xl);
  display: flex;
  gap: var(--gap-xl);
  z-index: 1000;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
}

.header .header-navbar {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.header nav {
  display: flex;
  flex: 1;
  gap: var(--gap-xl);
}

.header a,
.header nav a {
  display: flex;
  align-items: center;
  gap: var(--gap-xs);
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.4rem;
  cursor: pointer;
  transition: all .35s;
  text-decoration: none;
}

.header .header-navbar > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.8rem;
  width: 4.8rem;
}

.header .logo-sm {
  display: none;
}

.header a:hover,
.header nav a:hover {
  color: var(--accent-hover-color);
}

.header a.active,
.header nav a.active {
  color: var(--accent-color);
  border-bottom: 2px solid var(--accent-color);
}

.header-navbar > a.active {
  color: inherit;
  border-bottom: none;
}

@media (max-width: 768px) {
  .header {
    gap: var(--gap-sm);
    padding: var(--gap-sm);
  }

  .header .logo {
    display: none;
  }

  .header .logo-sm {
    display: block;
    width: 6rem;
  }
}
