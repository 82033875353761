.slider-container {
  display: flex;
  gap: var(--gap-ss);
  overflow-x: scroll;
  scroll-behavior: smooth;
  cursor: grab;
  user-select: none;
}

.slider-container:active {
  cursor: grabbing;
}

.slider-container::-webkit-scrollbar {
  display: none;
}

.slider-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slider-item {
  flex: 0 0 auto;
  cursor: pointer;
}
