.message-chat-board {
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);
}

.message-chat-board .messages-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-ss);
  max-height: 40rem;
  overflow-y: auto;
  margin-top: var(--gap-ss);
}

.message-chat-board .messages-dropdown {
  max-width: 25rem;
  margin: 0 auto;
}

.message-chat-board .message-date {
  text-align: center;
  margin: var(--gap-ss) 0;
  font-weight: 600;
  color: var(--card-border-color);
}

.message-chat-board .message-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.message-chat-board .message-container.msg_inbound {
  align-items: flex-start;
}

.message-chat-board .message-container.msg_outbound {
  align-items: flex-end;
}

.message-chat-board .message-container.msg_inbound .chat-message {
  margin-left: var(--gap-md);
}

.message-chat-board .message-container.msg_outbound .chat-message {
  margin-right: var(--gap-md);
}

.message-chat-board .chat-message__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  margin-bottom: var(--gap-xs);
  position: relative;
}

.message-chat-board .chat-message__icon .chat-icon {
  width: 2.4rem;
  height: 2.4rem;
}

.message-chat-board .chat-message__icon.outbound-icon::before {
  content: attr(data-agent-name);
  position: absolute;
  font-weight: 500;
  right: 5.4rem;
  white-space: nowrap;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.message-chat-board .chat-message__icon.inbound-icon::after {
  content: attr(data-customer-name);
  position: absolute;
  font-weight: 500;
  left: 5.4rem;
  white-space: nowrap;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.inbound-icon {
  background-color: #f0f0f0;
}

.outbound-icon {
  background-color: #d1ecf1;
}

.message-chat-board .chat-message {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
}

.message-chat-board .chat-message p {
  margin: 0;
}

.message-chat-board .chat-message .chat-message__featured-text {
  display: flex;
  gap: var(--gap-sm);
  justify-content: space-between;
}

.message-chat-board .chat-message .chat-message__featured-text span {
  margin-top: 5px;
  font-size: 0.8em;
  font-weight: 600;
  color: var(--card-border-color);
}

.message-chat-board .chat-message.inbound {
  background-color: #f0f0f0;
  border-top-left-radius: 0;
}

.message-chat-board .chat-message.outbound {
  background-color: #d1ecf1;
  border-top-right-radius: 0;
}
