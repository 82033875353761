.faq {
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);
}

.faq .faq-query {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--card-border-color);
  background-color: var(--white);
  transition: all .35s;
}

.faq .faq-query .faq-query__question {
  display: flex;
  gap: var(--gap-md);
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.2rem;
  color: var(--h-color);
  padding: var(--gap-sm) var(--gap-md);
  cursor: pointer;
  transition: all .35s;
}

.call-card .faq .faq-query .faq-query__question {
  padding: var(--gap-ss) var(--gap-md);
}

.faq .faq-query .faq-query__question svg {
  min-width: 2.4rem;
  min-height: 2.4rem;
  transition: all .35s;
}

.faq .faq-query.open .faq-query__question svg {
  transform: rotate(-45deg);
}

.faq .faq-query .faq-query__answer {
  max-height: 0;
  overflow: hidden;
  padding: 0 var(--gap-md);
  transition: all .35s ease-out;
}

.faq .faq-query.open .faq-query__answer {
  max-height: 100%;
  overflow: visible;
  padding: 0 var(--gap-md) var(--gap-md);
}



.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;
}

.grid-header {
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid var(--card-border-color);
}

.grid-cell {
  padding: 10px;
  border-bottom: 1px solid var(--card-border-color);
}

.faq__line {
  height: 1px;
  width: 100%;
  background: var(--card-border-color);
  margin-bottom: var(--gap-sm);
}

.conversation-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.conversation-list {
  padding-right: var(--gap-md);
  max-height: 50rem;
  overflow-y: auto;
}

.conversation-role {
  display: flex;
  gap: var(--gap-sm);
  margin-left: 2.2rem;
}

.conversation-role p{
  display: inline-flex;
  align-items: center;
  gap: var(--gap-xs);
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--h-color);
}

.conversation-role span {
  display: inline-flex;
  height: 1.8rem;
  min-width: 1.8rem;
  border-radius: 50%;
}

.conversation-role--agent span{
  background-color: var(--accent-color);
}

.conversation-role--customer span{
  background-color: var(--card-border-color);
}

.conversation-item {
  display: flex;
  align-items: center;
  gap: .8rem;
  margin-bottom: 10px;
}

.conversation-item__bubble {
  display: block;
  height: 1.4rem;
  min-width: 1.4rem;
  border-radius: 50%;
}

.conversation-item.agent .conversation-item__bubble {
  background-color: var(--accent-color);
}

.conversation-item.customer .conversation-item__bubble {
  background-color: var(--card-border-color);
}

.conversation-item__message {
  flex: 1;
  padding: var(--gap-xs) var(--gap-sm);
  border-radius: var(--border-radius-lg);
  position: relative;
}

.conversation-item.agent .conversation-item__message {
  border: 1px solid var(--accent-color);
}

.conversation-item.customer .conversation-item__message {
  border: 1px solid var(--card-border-color);
}




.entity-recognized {
  display: flex;
  flex-direction: column;
  gap: var(--gap-ss);
}

.analysis p {
  display: flex;
  gap: var(--gap-xs);
  margin-bottom: var(--gap-ss);
}

.analysis p:last-of-type {
  margin-bottom: 0;
}

.analysis p span:first-child {
  min-width: max-content;
}

.metadata-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.call-timing-details, .crm-integration {
  display: flex;
  flex-direction: column;
}

.metadata-grid-item {
  padding: var(--gap-xs) 0;
}

h3 {
  font-size: 1.5rem;
}

.underline-link {
  text-decoration: underline;
}