.loader {
  display: flex;
  align-items: center;
  margin-top: var(--gap-md);
}

.dot {
  width: 1.6rem;
  height: 1.6rem;
  background-color: var(--accent-color);
  border-radius: 50%;
  margin: 0 5px;
  animation: leap 1s ease-in-out infinite, colorChange 2s infinite alternate;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes leap {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-20px);
  }
}

@keyframes colorChange {
  0%, 100% {
    background-color: var(--accent-color);
  }
  50% {
    background-color: #3498db;
  }
}
