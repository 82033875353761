.floating-chat {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--white);
  overflow: hidden;
  z-index: 1000;
  width: 60rem;
  height: 60rem;
  min-width: 60rem;
  min-height: 60rem;
  max-width: calc(100% - 3.2rem);
  max-height: calc(100% - 3.2rem);
  resize: both;
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--card-border-color);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.floating-chat.fullscreen {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
  margin: 0;
}


.floating-chat::after {
  content: "";
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18px 18px 0 0;
  border-color: var(--accent-color) transparent transparent transparent;
  cursor: nwse-resize;
}

.floating-chat-icon {
  display: block;
  position: fixed;
  bottom: var(--gap-lg);
  right: var(--gap-lg);
  cursor: pointer;
}

.floating-chat-icon.closed{
  display: none;
}

.floating-chat.closed {
  display: none;
}

.close-icon {
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  background-color: var(--accent-color);
  color: #fff;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  position: relative;
}

.chat-header-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap-ss);
  justify-self: flex-end
}

.chat-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - var(--header-height));
}

.chat-history {
  padding: var(--gap-ss);
  overflow-y: auto;
  max-height: calc(100% - 20rem);
}

.welcome-message {
  color: #555;
  font-size: 1em;
  text-align: center;
  padding: 20px;
}

.chat-entry {
  background-color: var(--accent-overlay);
  border-radius: var(--border-radius-md);
  padding: var(--gap-ss);
  margin-bottom: var(--gap-md);
 }

.chat-message,
.chat-message h1 {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
}

.chat-message .featured-text {
  font-size: 2rem;
}

.chat-response {
  margin-top: 5px;
}

.chat-response h2 {
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 600;
}

.chat-response h3 {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 600;
}

.chat-response > *,
.chat-response ol li p,
.chat-response ul li p {
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 400;
}

.chat-timestamp {
  display: flex;
  justify-content: flex-end;
  font-size: 0.8em;
  font-weight: 600;
  color: var(--card-border-color);
  margin-top: 5px;
}

.chat-input-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-ss);
  padding: var(--gap-ss);
  border-top: 1px solid var(--card-border-color);
  background-color: var(--white);
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.chat-input-area {
  display: flex;
  gap: var(--gap-ss);
}

.chat-input {
  flex: 1;
  border: 1px solid var(--card-border-color);
  background: var(--white);
  padding: var(--gap-ss);
  border-radius: .4rem;
  overflow: hidden;
  }

  .chat-input textarea {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border: none;
    resize: none;
    box-shadow: none;
    outline: none;
  }

.chat-btn-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);
}

.chat-btn-container .cta {
  min-width: initial;
}

.open .chat-body {
  display: block;
  padding-bottom: 50px;
}

.error-message {
  color: var(--error);
  font-size: 0.9em;
  margin-top: 5px;
}

.expand-btn {
  width: 100%;
  height: 6.4rem;
  min-width: 6.4rem;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.2);
  color: var(--accent-color);
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: 10px;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  backdrop-filter: blur(10px);
}

.expand-btn.expand-left {
  flex-direction: row-reverse;
}

.expand-btn .expand-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 6.4rem;
  height: 6.4rem;
  line-height: 6.4rem;
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 2em;
  cursor: pointer;
  vertical-align: middle;
  transition: 0.2s;
  background: var(--accent-color);
  color: white;
  flex: 0 0 auto;
}

.expand-btn .expand-text {
  display: inline-block;
  visibility: visible;
  color: var(--accent-color);
  flex-grow: 1;
  font-weight: 600;
  text-align: center;
}

.expand-btn .expand-icon img {
  height: 3.2rem;
  width: 3.2rem;
  transition: transform 0.3s ease-in-out;
}

.expand-btn:hover .expand-icon img {
  transform: rotate(360deg);
}

.chat-suggestions {
  overflow-x: auto;
}

.chat-suggestions__list {
  display: flex;
  gap: var(--gap-ss);
  padding-bottom: var(--gap-sm);
}

.chat-suggestions__item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--gap-xs);
  border: 1px solid var(--accent-color);
  border-radius: var(--border-radius-ss);
  min-width: max-content;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;
  cursor: pointer;
  transition: all .35s;
  user-select: none;
}

.chat-suggestions__item:hover {
  background-color: var(--accent-color);
  color: var(--white);
}

.chat-suggestions__item.active {
  background-color: var(--accent-active-color);
  color: var(--white);
}

.chat-suggestions__item:disabled {
  border-color: var(--card-border-color);
  opacity: 0.7;
  cursor: not-allowed;
} 