.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup-overlay-content {
  position: relative; 
  min-width: 35rem;
  max-width: 40rem;
}

.popup-overlay .close-icon {
  position: absolute;
  top: var(--gap-md);
  transform: translateY(0);
  right: var(--gap-md);
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
}

.form-group,
.popup-overlay-content h3 {
  margin-bottom: var(--gap-sm);
}

.form-group .radio-group {
  display: flex;
  gap: var(--gap-md);
}

.form-group .radio-group label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .4rem;
}

.form-group input[type="text"]:not(.dropdown-search-box input[type="text"]){
  border: 1px solid var(--card-border-color);
  padding: var(--gap-xs) var(--gap-sm);
  border-radius: var(--border-radius-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 9.4rem;
  overflow: hidden;
  min-width: 100%;
}

.form-group label:not(.dropdown-list-item label, .radio-group label) {
  color: var(--label-text);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: .4rem;
}

.form-group input[type="checkbox"] {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group input[type="checkbox"] + label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid var(--accent-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group input[type="checkbox"]:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid var(--accent-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.form-actions {
  display: flex;
  justify-content: space-between;
  gap: var(--gap-md);
}

.form-actions button {
  min-width: initial;
}

.error {
  color: var(--error);
  margin-top: 10px;
}

.confirmation-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-content {
  display: flex;
    text-align: center;
    flex-direction: column;
    gap: var(--gap-md);
  
}

.confirmation-content p {
  font-weight: 600;
}

.form-error {
  color: var(--error);
  margin-top: 5px;
  font-size: 1.2rem;
}

