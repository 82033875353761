.admin-table-container {
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.table-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}


.admin-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.admin-table-container h2 {
  margin-bottom: var(--gap-sm);
}

.admin-table-header {
  font-weight: bold;
  text-align: left;
}

.admin-table-header th:first-child {
  border-top-left-radius: var(--border-radius-lg);
  border-bottom-left-radius: var(--border-radius-lg);
}

.admin-table-header th:last-child {
  border-top-right-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
}

.admin-table-row td:first-child {
  border-top-left-radius: var(--border-radius-lg);
  border-bottom-left-radius: var(--border-radius-lg);
}

.admin-table-row td:last-child {
  border-top-right-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
}

.admin-table th,
.admin-table td {
  padding: var(--gap-sm);
}

.admin-table-row {
  border-radius: 16px;
  background-color: var(--call-item-bg-color);
  cursor: pointer;
}

.table-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--gap-md);
}

.admin-table-icon {
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  margin: 0 5px;
}

.admin-table-icons {
  display: flex;
  gap: var(--gap-ss);
  align-items: center;
}

.status-icon {
  font-size: 18px;
}
span.active,
span.inactive {
  display: flex;
  gap: .4rem;
  align-items: center;
}

span.active::before {
  content: '';
  display: block;
  height: .8rem;
  width: .8rem;
  border-radius: 50%;
  background-color: var( --success);
}

span.inactive::before {
  content: '';
  display: block;
  height: .8rem;
  width: .8rem;
  border-radius: 50%;
  background-color: var( --error);
}
