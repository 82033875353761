.date-range-selector .picker-container {
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--card-border-color);
  background: var(--white);
  overflow: hidden;
  z-index: 3;
}

.date-range-selector .picker-container .rdrStaticRanges {
  font-family: var(--userfont);
  gap: var(--gap-xs);
}

.date-range-selector .picker-container .rdrDateRangePickerWrapper {
  flex-direction: row-reverse;
}
.date-range-selector .picker-container .rdrCalendarWrapper {
  border: none;
}

.date-range-selector .picker-container .rdrNextPrevButton {
  background: transparent;
  border: none;
  cursor: pointer;
}

.date-range-selector .picker-container .rdrDay {
  border-radius: 50%;
}

.date-range-selector .picker-container .rdrDayToday .rdrDayNumber span:after {
  background: var(--accent-color);
}

.date-range-selector .picker-container .rdrDefinedRangesWrapper {
  border-left: 1px solid var(--card-border-color);
  padding: var(--gap-lg) var(--gap-md);
  width: 20rem;
  border-right: none;
  /* display: none; */
}

.date-range-selector .picker-container .rdrMonth {
  padding: 0 var(--gap-md) var(--gap-md);
}

.date-range-selector .picker-container .rdrMonth:first-child {
  border-right: 1px solid var(--card-border-color);
}

.date-range-selector .picker-container .rdrMonthAndYearWrapper {
  padding: var(--gap-md) var(--gap-md) 0;
}

.date-range-selector .picker-container .rdrDefinedRangesWrapper .rdrInputRanges {
  display: none;
}

.date-range-selector .picker-container .rdrDateDisplayWrapper {
  display: none;
}

.date-range-selector .picker-container .rdrMonths.rdrMonthsHorizontal {
  border-bottom: 1px solid var(--card-border-color);
  flex-grow: 1;
}

.date-range-selector .picker-container .rdrDefinedRangesWrapper .rdrStaticRangeLabel {
  padding: 0;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: -0.0084rem;
  color: var(--label-text);
  width: 100%;
  transition: all .35s;
}

.date-range-selector .picker-container .rdrDefinedRangesWrapper .rdrStaticRange:hover .rdrStaticRangeLabel {
  color: var(--accent-color);
  background: transparent;
}

.date-range-selector .picker-container .rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  background: var(--accent-overlay);
  border-radius: var(--border-radius-sm);
}

.date-range-selector .picker-container .rdrDefinedRangesWrapper .rdrStaticRangeSelected .rdrStaticRangeLabel {
  color: var(--accent-color);
  font-weight: 700;
}

.date-range-selector .picker-container .rdrStaticRanges .rdrStaticRange {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--gap-ss) var(--gap-sm);
  border-bottom: none;
}

