.last-call .last-call-range {
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);
  position: relative;
}

.last-call .last-call-range h3 {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.6rem;
  width: min-content;
  text-align: center;
}

.last-call .last-call-range h4 {
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.7rem; 
}

.last-call .last-call-range .last-call-priority,
.last-call .last-call-range .last-call-review {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-ss);
}

.last-call .last-call-range .last-call-priority .last-call-tooltip {
  display: block;
  padding: var(--gap-ss);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--card-border-color);
  background: var(--white);
  width: 16rem;
  min-height: 14rem;
  position: absolute;
  right: 110%;
  top: 0;
  transform: translateX(-100%);
  opacity: 0;
  transition: all .35s;
}

.last-call-tooltip::before {
  position: absolute;
  right: -10px;
  top: 10rem;
  transform: translateY(-50%);
  content: '';
  height: 0;
  width: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--white);
}

.last-call .last-call-range .last-call-priority:hover .last-call-tooltip  {
  transform: translateX(0);
  opacity: 1;
}

@media (max-width: 576px) {
  .last-call .last-call-range {
    flex-direction: row;
  }

  .last-call .last-call-range .last-call-priority,
  .last-call .last-call-range .last-call-review {
    flex: 1;
  }
}
