.overall-stats {
  display: flex;
  gap: var(--gap-md);
  margin-top: var(--gap-md);
}

.overall-stats .overall-stats__item {
  display: flex;
  gap: var(--gap-ss);
  padding: var(--gap-ss) var(--gap-md) var(--gap-ss) var(--gap-ss);
  align-items: center;
  border-radius: var(--border-radius-sm);
  min-width: 21.2rem;
}

.overall-stats .overall-stats__item-icon {
  display: flex;
  gap: var(--gap-ss);
  align-items: center;
  justify-content: center;
  height: 5.6rem;
  width: 5.6rem;
  border-radius: var(--border-radius-md);
}

.overall-stats .overall-stats__item-icon svg {
  height: 3.2rem;
  width: 3.2rem;
}

.overall-stats .overall-stats__item-content p {
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.16px;
  color: var(--agent-stats-text-color);
  margin-bottom: 1.4rem;
}

.overall-stats .overall-stats__item-content h3 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0.2px;
}