.pagination {
  display: flex;
  gap: var(--gap-sm);
  justify-content: flex-end;
  align-items: center;
  margin: var(--gap-sm) 0;
}

.custom-select-wrapper {
  position: relative;
  display: inline-block;
}

.items-per-page-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-width: 4.8rem;
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--card-border-color);
  background-color: var(--white);
  color: var(--text-color);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4rem;
  cursor: pointer;
  transition: all .35s;
}

.cta.cta-secondary.cta--pagination.items-per-page-select {
  padding: var(--gap-ss) var(--gap-lg) var(--gap-ss) var(--gap-ss);
}

.items-per-page-select:focus {
  border-color: var(--accent-color);
  outline: none;
}

.items-per-page-select:hover {
  border-color: var(--accent-color);
  box-shadow: var(--secondary-hover-shadow);
}

.items-per-page-select:active {
  background-color: var(--accent-color);
  color: var(--white);
  box-shadow: var(--secondary-active-shadow);
}

.custom-select-wrapper::after {
  content: '❯';
  position: absolute;
  top: 50%;
  right: var(--gap-sm);
  transform: translateY(-50%) rotate(90deg);
  pointer-events: none;
  color: var(--text-color);
}

.custom-select-wrapper:has(.items-per-page-select:active)::after {
  color: var(--white);
}

.items-per-page-select option {
  background-color: var(--white);
  color: var(--text-color);
}

.items-per-page-select option:hover {
  background-color: var(--accent-hover-color);
  color: var(--white);
}
